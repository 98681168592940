import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const MapImage = () => (
  <a
    href="https://g.page/lodgeatpinecove"
    rel="noopener noreferrer"
    target="_blank"
  >
    <StaticImage alt="Lodge at Pine Cove map" src="../images/map.png" />
  </a>
)

export default MapImage
