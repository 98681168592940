import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"
import Layout from "../components/Layout"
import MapImage from "../components/MapImage"
import SEO from "../components/SEO"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Grid container spacing={3}>
      <Grid item sm={3} xs={12}>
        <Typography variant="body1" style={{ marginBottom: "1rem" }}>
          24900 Marion Ridge Dr., Idyllwild, CA 92549
        </Typography>
        <Typography variant="body1">(951) 468-0389</Typography>
      </Grid>
      <Grid item sm={9} xs={12}>
        <MapImage />
      </Grid>
    </Grid>
  </Layout>
)

export default Contact
